<template>
    <section>
        <div id="login" class="container">
            <div class="row justify-content-center">
                <div class="card border-0 col-8 mt-3">
                    <div class="card-body">
                        <div class="card border-0">
                            <img src="../assets/logo.png" class="card-img-top w-75 m-auto" alt="...">
                            <div class="card-body p-0">

                                <div class="form-floating mb-3 border-0">
                                    <input type="email" class="form-control border-bottom" id="floatingInput"
                                        placeholder="name@example.com">
                                    <label for="floatingInput">{{ this._LangStatic[0][this.props][0].epostaadresi }}</label>
                                </div>
                                <div class="form-floating">
                                    <input type="password" class="form-control border-bottom" id="floatingPassword"
                                        placeholder="Password">
                                    <label for="floatingPassword">{{ this._LangStatic[0][this.props][0].sifre }}</label>
                                </div>
                                <div v-if="LoginSuccess !== null">
                                    <div v-if="!LoginSuccess"
                                        class="alert alert-danger col-sm-8 p-1 d-flex justify-content-center m-auto mb-4 mt-2"
                                        role="alert">
                                        <strong class="text-capitalize">{{ this._LangStatic[0][this.props][0].girishatali }}</strong>
                                    </div>
                                    <div v-if="LoginSuccess"
                                        class="alert alert-success col-sm-8 p-1 d-flex justify-content-center m-auto mb-4 mt-2"
                                        role="alert">
                                        <strong class="text-capitalize">{{ this._LangStatic[0][this.props][0].girisbasarili }}</strong>
                                    </div>
                                </div>
                                <button class="btn btn-md text-capitalize">{{ this._LangStatic[0][this.props][0].giris }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
const $ = require("jquery")
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            LoginSuccess: false, // true-false-null
            props: "tr"
        }
    },
    methods:{},
    computed: {
        ...mapGetters(['_LangStatic']),
        
    },
    beforeUpdate() {
        const newHtmlLang = $("html").attr("lang")
        this.props = newHtmlLang
    },
}

</script>