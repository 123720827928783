<template>
  <section>
    <div class="d-none mt-5 alert alert-danger col-6 d-flex justify-content-center m-auto mb-4" role="alert">
      Giriş hatalı
    </div>
    <div class="container-fruid" id="panel">
      <div class="duyurulartitle col-12">
        <h6 class="text-uppercase">{{ this._LangStatic[0][this.props][0].duyurular }}</h6>
      </div>
      <marquee behavior="scroll" direction="left" scrollamount="5">
        <ul id="duyurular" class="list-group list-group-horizontal">
          <li class="list-group-item ">
            <div class="card bg-dark text-white">
              <img src="../assets/img/duyurular.jpg" class="card-img" alt="...">
              <div class="card-img-overlay">

              </div>
            </div>
          </li>
          <li class="list-group-item ">
            <div class="card bg-dark text-white">
              <img src="../assets/img/duyurular.jpg" class="card-img" alt="...">
              <div class="card-img-overlay">

              </div>
            </div>
          </li>
          <li class="list-group-item ">
            <div class="card bg-dark text-white">
              <img src="../assets/img/duyurular.jpg" class="card-img" alt="...">
              <div class="card-img-overlay">

              </div>
            </div>
          </li>
        </ul>
      </marquee>


      <div class="navmenu mb-3 px-4 d-flex justify-content-center">
        <div class="row">
          <ul class="list-group list-group-horizontal border-0">
            <li class="list-group-item">
              <button @click="ButtonClick" class="btn btn-primary m-auto border-0 me-2" type="button"
                data-bs-toggle="collapse" data-bs-target="#urunekle" aria-expanded="false" aria-controls="urunekle">
                {{ this._LangStatic[0][this.props][0].urunekle }}
              </button>
            </li>
            <li class="list-group-item">
              <button @click="ButtonClick" class="btn btn-primary m-auto border-0 me-2" type="button"
                data-bs-toggle="collapse" data-bs-target="#reklamekle" aria-expanded="false"
                aria-controls="reklamekle">
                {{ this._LangStatic[0][this.props][0].reklamekle }} 
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div class="container navmenucollpse d-flex flex-column">
        <div class="row">

          <div class="col-12">
            <div class="collapse multi-collapse" id="urunekle">
              <div class="card card-body">
                <div id="urun_bilgi" class="card border-0 bg-transparent">
                  <h5 class="card-header bg-transparent text-capitalize">{{ this._LangStatic[0][this.props][0].urunekle }}</h5>
                  <div class="card-body">
                    <form class="form-floating mb-3">
                      <input type="text" class="form-control is-invalid" id="floatingInputInvalid1" placeholder="...">
                      <label class="text-capitalize" for="floatingInputInvalid1">{{ this._LangStatic[0][this.props][0].urunadi }}</label>
                    </form>

                    <form class="form-floating mb-3">
                      <select id="floatingInputInvalid2" class="form-select is-invalid text-capitalize"
                        aria-label="Default">
                        <option value="null" selected>{{ this._LangStatic[0][this.props][0].secimyapiniz }}</option>
                        <option value="1">one</option>
                        <option value="2">Two</option>
                      </select>
                      <label class="text-capitalize" for="floatingInputInvalid17">{{ this._LangStatic[0][this.props][0].urun }} / {{ this._LangStatic[0][this.props][0].hizmet }}</label>
                    </form>

                    <form class="form-floating mb-3">
                      <select id="floatingInputInvalid3" class="form-select is-invalid text-capitalize"
                        aria-label="Default">
                        <option value="null" selected>{{ this._LangStatic[0][this.props][0].secimyapiniz }}</option>
                        <option value="1">one</option>
                        <option value="2">Two</option>
                      </select>
                      <label class="text-capitalize" for="floatingInputInvalid3">{{ this._LangStatic[0][this.props][0].satis }} / {{ this._LangStatic[0][this.props][0].alis }} </label>
                    </form>

                    <form class="form-floating mb-3">
                      <input type="text" class="form-control is-invalid" id="floatingInputInvalid4" placeholder="...">
                      <label class="text-capitalize" for="floatingInputInvalid4">{{ this._LangStatic[0][this.props][0].urunaciklamasi }}</label>
                    </form>

                    <form class="form-floating mb-3">
                      <select id="floatingInputInvalid5" class="form-select is-invalid text-capitalize"
                        aria-label="Default">
                        <option value="null" selected>{{ this._LangStatic[0][this.props][0].secimyapiniz }}</option>
                        <option value="1">one</option>
                        <option value="2">Two</option>
                      </select>
                      <label class="text-capitalize" for="floatingInputInvalid5">{{ this._LangStatic[0][this.props][0].ulke }}</label>
                    </form>



                    <div class="row g-2">
                      <div class="col-md">
                        <form class="form-floating mb-3">
                          <select id="floatingInputInvalid6" class="form-select is-invalid text-capitalize"
                            aria-label="Default">
                            <option value="null" selected>{{ this._LangStatic[0][this.props][0].secimyapiniz }}</option>
                            <option value="1">one</option>
                            <option value="2">Two</option>
                          </select>
                          <label class="text-capitalize" for="floatingInputInvalid6">{{ this._LangStatic[0][this.props][0].sektor }}</label>
                        </form>
                      </div>
                      <div class="col-md">
                        <form class="form-floating mb-3">
                          <select id="floatingInputInvalid11" class="form-select is-invalid text-capitalize"
                            aria-label="Default">
                            <option value="null" selected>{{ this._LangStatic[0][this.props][0].secimyapiniz }}</option>
                            <option value="1">one</option>
                            <option value="2">Two</option>
                          </select>
                          <label class="text-capitalize" for="floatingInputInvalid11">{{ this._LangStatic[0][this.props][0].kategori }}</label>
                        </form>
                      </div>
                    </div>



                    <form class="form-floating mb-3">
                      <input type="text" class="form-control is-invalid" id="floatingInputInvalid7" placeholder="...">
                      <label class="text-capitalize" for="floatingInputInvalid7">{{ this._LangStatic[0][this.props][0].miktar }}</label>
                    </form>

                    <form class="form mb-3">
                      <input value="yesnonoyes-123123" type="text" class="form-control is-valid" readonly>
                    </form>

                    <div class="input-group mb-3">
                      <label name="urun_resim_label"
                        class="input-group-text w-100 text-capitalize bg-transparent is-invalid rounded"
                        for="inputGroupFile01">{{ this._LangStatic[0][this.props][0].resimekle }}</label>
                      <input name="urun_resim_input" type="file" class="form-control d-none " id="inputGroupFile01">
                    </div>

                    <button name="urun_veri_kaydet"
                      class="btn btn-md text-capitalize float-end btn-success my-3">{{ this._LangStatic[0][this.props][0].kaydet }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="collapse multi-collapse" id="reklamekle">
              <div class="card card-body">
                <div class="card border-0 bg-transparent">
                  <h5 class="card-header bg-transparent text-capitalize">{{ this._LangStatic[0][this.props][0].reklamekle }}</h5>
                  <div class="card-body">
                    <form class="form-floating mb-3">
                      <input type="text" class="form-control is-invalid" id="floatingInputInvalid14" placeholder="...">
                      <label class="text-capitalize" for="floatingInputInvalid14">{{ this._LangStatic[0][this.props][0].baslik }}</label>
                    </form>

                    <form class="form-floating mb-3">
                      <input type="text" class="form-control is-invalid" id="floatingInputInvalid15" placeholder="...">
                      <label class="text-capitalize" for="floatingInputInvalid15">{{ this._LangStatic[0][this.props][0].altbaslik }}</label>
                    </form>

                    <div class="input-group mb-3">
                      <label name="reklam_resim_label"
                        class="input-group-text w-100 text-capitalize bg-transparent is-invalid rounded"
                        for="inputGroupFile011">{{ this._LangStatic[0][this.props][0].resimekle }}</label>
                      <input name="reklam_resim_input" type="file" class="form-control d-none" id="inputGroupFile011">
                    </div>
                    <button class="btn btn-md text-capitalize float-end btn-success my-3">{{ this._LangStatic[0][this.props][0].kaydet }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from 'vuex';
const $ = require("jquery");
export default {
  data() {
    return {
      props: "tr"
    }
  },
  methods: {
    // PANEL MENU BUTTON SHOW HİDE YAPMAK
    ButtonClick(e) {
      const ItemClassList = e.target.classList
      const classCheck = new Array()
      ItemClassList.forEach(v => {
        classCheck.push(v)
      });
      $(".navmenucollpse .collapse").removeClass("show");
      $(".navmenu button").removeClass("shadow-none")
      $(e.target).addClass("shadow-none")
      if ((classCheck.indexOf("shadow-none")) > 0) $(".navmenu button").removeClass("shadow-none")
    }// PANEL MENU BUTTON SHOW HİDE YAPMAK
  },
  computed: {
    ...mapGetters(['_LangStatic']),
  },
  beforeUpdate() {
    const newHtmlLang = $("html").attr("lang")
    this.props = newHtmlLang
  },
}
</script>