<template>
    <section id="helper" class="container-fruid mx-3">
        <div class="row">
            <article class="col-12" id="player">
                <div class="embed-responsive embed-responsive-16by9 d-flex justify-content-center">
                    <!-- <iframe class="embed-responsive-item" :src='`http://www.youtube.com/embed/${IframeSrc}?controls=1`'>
                    </iframe> -->
                    <iframe width="560" height="315" :src="`https://www.youtube.com/embed/${IframeSrc}`" 
                        title="YouTube video player" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen></iframe>
                </div>
            </article>
            <article class="col-12" id="menu">
                <div class="card bg-transparent border-0 mt-2">
                    <div class="card-body ">
                        <ul class="list-group list-group-flush">
                            <form class="d-flex">
                                <input v-model="filterKey" class="form-control me-2" type="search" placeholder="Arama"
                                    aria-label="Search">

                                <button class="btn btn-outline-success" type="submit"><i
                                        class="fa-solid fa-magnifying-glass"></i></button>
                            </form>
                            <li v-for="(item) in videoList " :key="(item)" class="bg-transparent list-group-item">
                                <div @click="this.IframeSrc = item[1]" class="card my-1">
                                    <div class="row g-0">
                                        <div class="col-md-4 col-4">
                                            <img :src="item[0]" class="img-fluid rounded-start" :alt="item[2]">
                                        </div>
                                        <div class="col-md-8 col-8">
                                            <div class="card-body">

                                                <h5 class="card-title">{{ item[2] }}</h5>

                                                <p class="card-text d-flex justify-content-end"><small>{{
                                                    item[3] }} yüklendi.</small></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>

                    </div>
                </div>
            </article>
        </div>

    </section>
</template>
<script>
//import axios from "axios"
const $ = require("jquery")
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            IframeSrc: "8kO795s1rJQ",
            filterKey: ""
        }
    },
    methods: {
        // BACEND SORGU ATILARAK YENİ VERİLER ÇEKİLMESİNDE KULLANILACAK HER GÜN 1 SORGU OLMASI SAĞLANACAK
        youtubeUpload() {
            const dateNow = Intl.DateTimeFormat('tr-TR').format(Date.now())
            const storeDate = Intl.DateTimeFormat('tr-TR').format(this._videolar[0].updateDate[0]) //Intl.DateTimeFormat('tr- TR').format()

            if (dateNow === storeDate) {
                console.log("başarılı")
            } else {
                console.log("başarısız")
            }

        },
        // BACEND SORGU ATILARAK YENİ VERİLER ÇEKİLMESİNDE KULLANILACAK HER GÜN 1 SORGU OLMASI SAĞLANACAK

    },
    computed: {
        ...mapGetters(["_videolar"]),
        videoList() {
            if (this.filterKey.length > 0) {
                const videoList = this._videolar[0].video
                const videoListArray = new Array
                const filterKeyValue = this.filterKey
                $.each(videoList, function (i, v) {
                    if (v[2].toLowerCase().trim().includes(filterKeyValue.trim().toLowerCase()) === true) videoListArray.push([...v])
                });
                console.log(videoListArray)
                return videoListArray
            } else {
                return this._videolar[0].video
            }
        }
    }
}
</script>



      <!-- ?si=bUZiv70PQu8uEIlk -->
