import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import PanelView from '../views/PanelView.vue'
import LoginView from '../views/LoginView.vue'
import HelperView from '../views/HelperView.vue'
const $ = require("jquery")
const routes = [
  {
    path: '/home/:lang',
    name: 'home',
    component: HomeView,

  },
  {
    path: '/login/:lang',
    name: 'login',
    component: LoginView,
  },
  {
    path: '/panel/:lang',
    name: 'panel',
    component: PanelView,
  },
  {
    path: '/helper/:lang',
    name: 'helper',
    component: HelperView,
  },
  
]

const router = createRouter({
  history: createWebHistory(),
  routes,

})
router.beforeEach((to, from, next) => {
  const MyPath = Array()
  routes.forEach(v => {
    const CheckName = v.name
    MyPath.push(CheckName)
  });
  MyPath.push("")
  const UrlPathLang = to.path.split('/')[2]
  const CheckPath = MyPath.indexOf(to.path.split('/')[1])
  // CHECK PATH
  if (CheckPath === -1 || CheckPath === undefined) router.push("/home/tr")
  if (UrlPathLang === undefined) router.push("/home/tr")
  // CHECK PATH
  const SiteLang = $("html").attr("lang")
  if (UrlPathLang !== undefined && SiteLang !== UrlPathLang) $("html").attr("lang", `${UrlPathLang}`)
  
  //console.log($("head meta[name='description']").attr("content"))
  next()
})//FARKLI URL OLDUĞUNDA YÖNLENDİRME
export default router
