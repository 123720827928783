<template>
  <dialog @click="DialogHidwİmg" :open="ShowİmgDialog" class="urun_en_son_ekle_resim">
    <form method="dialog">
      <picture v-if="ShowİmgDialog !== null && ShowİmgDialog !== false">
        <img :src="require(`../assets/img/urun_img/${ShowİmgDialog}`)">
      </picture>
    </form>
  </dialog>
  <dialog @click="DialogHideAyrinti" :open="ShowAyrintiDialog"
    class="urun_en_son_ekle_ayrinti">
    <form method="dialog">
      <div class="card col-10 col-sm-10 col-10">
        <div class="card-header text-capitalize py-3">
          <h5>{{ this._LangStatic[0][this.props][0].urunayrintilari }}</h5><small>{{
            this._LangStatic[0][this.props][0].satis }}</small>
        </div>
        <div class="card-body">
          <ul class="list-group list-group-flush">
            <li class="list-group-item text-capitalize d-flex"><strong class="me-1">{{
              this._LangStatic[0][this.props][0].sektor }} :</strong>
              <p>deneme</p>
            </li>
            <li class="list-group-item text-capitalize d-flex"><strong class="me-1">{{
              this._LangStatic[0][this.props][0].kategori }} :</strong>
              <p>deneme</p>
            </li>
            <li class="list-group-item text-capitalize d-flex"><strong class="me-1">{{
              this._LangStatic[0][this.props][0].miktar }} :</strong>
              <p>deneme</p>
            </li>
            <li class="list-group-item text-capitalize d-flex"><strong class="me-1">{{
              this._LangStatic[0][this.props][0].seri }} :</strong>
              <p>deneme</p>
            </li>
            <li class="list-group-item text-capitalize d-flex"><strong class="me-1">{{
              this._LangStatic[0][this.props][0].tarih }} :</strong>
              <p>deneme</p>
            </li>
            <li class="list-group-item text-capitalize d-flex"><strong class="me-1">{{
              this._LangStatic[0][this.props][0].acıklama }} :</strong>
              <p>deneme</p>
            </li>
          </ul>
        </div>
        <div class="card-footer justify-content-end d-flex">
          <a href="http://e-exportcity.com/firma/" type="button" class="btn btn-warning text-capitalize text-muted"><i
              class="fa-solid fa-angles-right me-1"></i>{{ this._LangStatic[0][this.props][0].firmabilgisigor }}</a>
        </div>
      </div>
    </form>
  </dialog>
  <div @click="BilgiAlert" style="z-index: 100;" class="alert alert-danger col-10 offset-1 text-capitalize position-fixed"
    role="alert">
    <ul class="list-group list-group-flush">
      <li class="list-group-item bg-transparent">- satın alma ve üyelik işlemleri masaüstü sürümünden yapılmaktadır!!</li>
      <li class="list-group-item bg-transparent">- Purchases and membership transactions are made from the desktop version!!</li>
    </ul>
  </div>
  <section>
    <div id="home">
      <!-- <p>{{ currentSection }}</p> -->
      <div class="d-none alert alert-danger col-10 d-flex justify-content-center m-auto mb-4 position-fixed z-9 "
        role="alert">
        {{ this._LangStatic[0][this.props][0].girishatali }}
      </div>
      <div class="card bg-light border-0">
        <div class="card-body">
          <aside id="slayder">
            <!-- Swiper -->
            <swiper class="headerswiper" :slidesPerView="'auto'" :spaceBetween="30"
              :autoplay="{ delay: 10000, disableOnInteraction: false, }" :modules="modules">
              <swiper-slide><img src="../assets/img/slayder_img/slayt1.jpg" alt=""></swiper-slide>
              <swiper-slide><img src="../assets/img/slayder_img/slayt2.jpg" alt=""></swiper-slide>
              <swiper-slide><img src="../assets/img/slayder_img/slayt3.jpg" alt=""></swiper-slide>
              <swiper-slide><img src="../assets/img/slayder_img/slayt1.jpg" alt=""></swiper-slide>
              <swiper-slide><img src="../assets/img/slayder_img/slayt2.jpg" alt=""></swiper-slide>

              <!-- <div class="swiper-pagination"></div> -->
            </swiper>
          </aside>
          <aside id="blog_bilgi">
            <!-- Modal NASIL ÇALIŞIR -->
            <div class="modal fade" id="nasılcalisir" tabindex="-1" aria-labelledby="nasılcalisirLabel"
              aria-hidden="true">
              <div class="modal-dialog modal-fullscreen">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title text-capitalize" id="nasılcalisirLabel">
                      {{ this._LangStatic[0][this.props][0].nasilcalisir }}
                    </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <p>
                      &nbsp; {{ this._LangStatic[0][this.props][0].nasilcalisirmetin1 }}
                      <br>
                      &nbsp; {{ this._LangStatic[0][this.props][0].nasilcalisirmetin2 }}
                    </p>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-danger text-capitalize" data-bs-dismiss="modal">kapat</button>
                  </div>
                </div>
              </div>
            </div>
            <!-- Modal / -->
            <!-- Modal BROKER SİSTEMİ  -->
            <div class="modal fade" id="brokersistemi" tabindex="-1" aria-labelledby="brokersistemiLabel"
              aria-hidden="true">
              <div class="modal-dialog modal-xl">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title text-capitalize" id="brokersistemiLabel">{{
                      this._LangStatic[0][this.props][0].brokersistemi }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <p>
                      &nbsp; {{ this._LangStatic[0][this.props][0].brokersistemimetin1 }}<br>
                      &nbsp; {{ this._LangStatic[0][this.props][0].brokersistemimetin2 }}<br>
                      &nbsp; {{ this._LangStatic[0][this.props][0].brokersistemimetin3 }}
                    </p>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-danger text-capitalize" data-bs-dismiss="modal">kapat</button>
                  </div>
                </div>
              </div>
            </div>
            <!-- Modal / -->
            <!-- Modal reklam vermek  YAPILACAK METİN EKLE -->
            <div class="modal fade" id="reklamvermek" tabindex="-1" aria-labelledby="reklamvermekLabel"
              aria-hidden="true">
              <div class="modal-dialog modal-xl">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title text-capitalize" id="reklamvermekLabel">
                      {{ this._LangStatic[0][this.props][0].reklamvermek }}
                    </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <p>
                      &nbsp;
                      {{ this._LangStatic[0][this.props][0].reklamvermekmetin1 }}<br>
                    </p>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-danger text-capitalize" data-bs-dismiss="modal">kapat</button>
                  </div>
                </div>
              </div>
            </div>
            <!-- Modal / -->
            <!-- Modal GÜVENLİK -->
            <div class="modal fade" id="güvenlik" tabindex="-1" aria-labelledby="güvenlikLabel" aria-hidden="true">
              <div class="modal-dialog modal-xl">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title text-capitalize" id="güvenlikLabel">
                      {{ this._LangStatic[0][this.props][0].guvenlik }}
                    </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <p>
                      &nbsp; {{ this._LangStatic[0][this.props][0].guvenlikmetin1 }} <br />
                      &nbsp; {{ this._LangStatic[0][this.props][0].guvenlikmetin2 }} <br />
                    </p>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-danger text-capitalize" data-bs-dismiss="modal">kapat</button>
                  </div>
                </div>
              </div>
            </div>
            <!-- Modal / -->
            <!-- Modal temsilcilik -->
            <div class="modal fade" id="temsilcilik" tabindex="-1" aria-labelledby="temsilcilikLabel" aria-hidden="true">
              <div class="modal-dialog modal-xl">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title text-capitalize" id="temsilcilikLabel">
                      {{ this._LangStatic[0][this.props][0].temsilcilik }}
                    </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <p>
                      &nbsp;{{ this._LangStatic[0][this.props][0].temsilcilikmetin1 }}<br>
                      &nbsp;{{ this._LangStatic[0][this.props][0].temsilcilikmetin2 }}<br>
                    </p>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-danger text-capitalize" data-bs-dismiss="modal">kapat</button>
                  </div>
                </div>
              </div>
            </div>
            <!-- Modal / -->
            <!-- Modal nasıl çalışır -->
            <div class="row row-cols-2 row-cols-sm-3 row-cols-md-5 g-4 justify-content-center">
              <div class="col">
                <div class="card h-100">
                  <a href="javascript void(0)" data-bs-toggle="modal" data-bs-target="#nasılcalisir">
                    <div class="card-body">
                      <img class="w-50" src="../assets/img/ara_katman_img/nasilcalisir.png" alt="">
                    </div>
                    <div class="card-footer">
                      <small class="text-capitalize card-text">
                        {{ this._LangStatic[0][this.props][0].nasilcalisir }}
                      </small>
                    </div>
                  </a>
                </div>
              </div>
              <div class="col">
                <div class="card h-100">
                  <a href="javascript void(0)" data-bs-toggle="modal" data-bs-target="#brokersistemi">
                    <div class="card-body">
                      <img class="w-75" src="../assets/img/ara_katman_img/dunya.png" alt="">
                    </div>
                    <div class="card-footer">
                      <small class="text-capitalize card-text">
                        {{ this._LangStatic[0][this.props][0].brokersistemi }}
                      </small>
                    </div>
                  </a>
                </div>
              </div>
              <div class="col">
                <div class="card h-100">
                  <a href="javascript void(0)" data-bs-toggle="modal" data-bs-target="#reklamvermek">
                    <div class="card-body">
                      <img class="w-50" src="../assets/img/ara_katman_img/reklam.png" alt="">
                    </div>
                    <div class="card-footer">
                      <small class="text-capitalize card-text">{{ this._LangStatic[0][this.props][0].reklamvermek
                      }}</small>
                    </div>
                  </a>
                </div>
              </div>
              <div class="col">
                <div class="card h-100">
                  <a href="javascript void(0)" data-bs-toggle="modal" data-bs-target="#güvenlik">
                    <div class="card-body">
                      <img class="w-50" src="../assets/img/ara_katman_img/security.png" alt="">
                    </div>
                    <div class="card-footer">
                      <small class="text-capitalize card-text">{{ this._LangStatic[0][this.props][0].guvenlik }}</small>
                    </div>
                  </a>
                </div>
              </div>
              <div class="col">
                <div class="card h-100">
                  <a href="javascript void(0)" data-bs-toggle="modal" data-bs-target="#temsilcilik">
                    <div class="card-body">
                      <img class="w-50" src="../assets/img/ara_katman_img/temsilci.png" alt="">
                    </div>
                    <div class="card-footer">
                      <small class="text-capitalize card-text">{{ this._LangStatic[0][this.props][0].temsilcilik
                      }}</small>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </aside>
          <aside id="slayd_en_son_eklenenler">
            <div class="card bg-transparent">
              <h5 class="card-header text-capitalize">{{ this._LangStatic[0][this.props][0].ensoneklenenler }}</h5>
              <div class="card-body">
                <!-- Swiper -->
                <swiper class="ensoneklenenlerswiper" :slidesPerView="'auto'" :spaceBetween="30"
                  :autoplay="{ delay: 10000, disableOnInteraction: false, }" :modules="modules">

                  <swiper-slide v-for="urun in _urunler" :key="urun">
                    <div class="card mb-3">
                      <div class="row g-0">
                        <div class="col-sm-4 col-4 d-flex align-items-center">
                          <img 
                          data-img-urun="1" 
                          :src="require(`../assets/img/urun_img/${urun.imgsrc}`)" 
                          class="img-fluid rounded-start"
                          alt="...">
                          <i @click="DialogShowİmg(`${urun.imgsrc}`)" class="fa-solid fa-magnifying-glass-plus"></i>
                        </div>
                        <div class="col-sm-8 col-8">
                          <div data-ayrinti-urun="1" class="card-body">
                            <h5 class="card-title text-capitalize">
                              {{ urun.urunadi[0][this.props] }}
                            </h5>
                            <p class="card-text text-capitalize text-start text-muted">
                              {{ urun.aciklama[0][this.props] }}
                            </p>
                            <p class="card-text d-flex justify-content-between">
                              <!-- <small class="text-muted text-capitalize">Last updated 3
                                mins ago</small> -->
                              <small @click="DialogShowAyrinti(1)" class="text-capitalize text-danger">ayrıntılı
                                incele...</small>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </swiper-slide>
                  <!-- <swiper-slide>
                    <div class="card mb-3">
                      <div class="row g-0">
                        <div class="col-sm-4 col-4 d-flex align-items-center">
                          <img data-img-urun="2" src="../assets/img/urun_img/urun2.jpg" class="img-fluid rounded-start"
                            alt="...">
                          <i @click="DialogShowİmg(2)" class="fa-solid fa-magnifying-glass-plus"></i>
                        </div>
                        <div class="col-sm-8 col-8">
                          <div data-ayrinti-urun="2" class="card-body">
                            <h5 class="card-title text-capitalize">mobilya malzemesi
                            </h5>
                            <p class="card-text text-capitalize text-start text-muted">
                              Lorem ipsum dolor sit amet consectetur adipisicing elit.
                              Ea voluptate voluptatum enim amet eligendi dolore
                              dolorum placeat quo nostrum libero? Consectetur ullam
                              inventore distinctio, quos voluptas accusamus quia
                              dignissimos corporis?
                            </p>
                            <p class="card-text d-flex justify-content-between">
                              
                              <small @click="DialogShowAyrinti(2)" class="text-capitalize text-danger">ayrıntılı
                                incele...</small>
                            </p>

                          </div>
                        </div>
                      </div>
                    </div>
                  </swiper-slide> -->
                  <!-- <div class="swiper-pagination"></div> -->
                </swiper>
              </div>
            </div>
          </aside>
          <aside id="slayd_urun_hizmetler">
            <div class="card bg-transparent">
              <h5 class="card-header text-capitalize">{{ this._LangStatic[0][this.props][0].urunlerhizmetler }}</h5>
              <!--FİLTER CANVAS-->
              <div class="d-grid gap-2 d-md-flex m-2 justify-content-end">
                <button class="filterbutton btn btn-primary btn-md text-capitalize" type="button"
                  data-bs-toggle="offcanvas" data-bs-target="#urun_hizmet_filtre" aria-controls="urun_hizmet_filtre">
                  <i class="fa-solid fa-filter"></i>
                </button>
              </div>
              <div class="offcanvas offcanvas-start" tabindex="-1" id="urun_hizmet_filtre"
                aria-labelledby="urun_hizmet_filtreLabel">
                <div class="offcanvas-header">
                  <h5 class="offcanvas-title text-capitalize" id="urun_hizmet_filtreLabel">{{
                    this._LangStatic[0][this.props][0].filtreleme }}
                  </h5>
                  <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
                </div>
                <div class="offcanvas-body">
                  <div class="card">
                    <h6 class="card-title mb-2 text-muted text-capitalize">{{
                      this._LangStatic[0][this.props][0].secimyapiniz }}</h6>
                    <div class="card-body">
                      <ul class="list-group list-group-flush">
                        <li class="list-group-item">
                          <form class="form-floating d-flex flex-coloumb">
                            <input name="urunserinumber" type="text" class="form-control is-invalid" id="urunserinumber"
                              placeholder="seri numarası girişi yapınız">
                            <label for="urunserinumber">{{ this._LangStatic[0][this.props][0].urunserinumarasi }}</label>
                            <button class="btn btn-outline-warning text-capitalize">{{
                              this._LangStatic[0][this.props][0].filtrele }}</button>
                          </form>
                        </li>
                        <li class="list-group-item">
                          <div class="row">
                            <select class="form-select is-invalid text-capitalize mb-1"
                              aria-label="Default select example">
                              <option value="null" selected>{{ this._LangStatic[0][this.props][0].urun }} / {{
                                this._LangStatic[0][this.props][0].hizmet }}</option>
                              <option value="1">{{ this._LangStatic[0][this.props][0].urun }} </option>
                              <option value="2">{{ this._LangStatic[0][this.props][0].hizmet }} </option>
                            </select>

                            <select class="form-select is-invalid text-capitalize" aria-label="Default select example">
                              <option value="null" selected>{{ this._LangStatic[0][this.props][0].satis }} / {{
                                this._LangStatic[0][this.props][0].talep }} </option>
                              <option value="1">{{ this._LangStatic[0][this.props][0].satis }} </option>
                              <option value="2">{{ this._LangStatic[0][this.props][0].talep }} </option>
                            </select>
                          </div>


                        </li>
                        <li class="list-group-item">
                          <select class="form-select is-invalid text-capitalize" aria-label="Default select example">
                            <option value="null" selected>{{ this._LangStatic[0][this.props][0].ulke }} </option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </select>
                        </li>
                        <li class="list-group-item">
                          <select class="form-select is-invalid text-capitalize" aria-label="Default select example">
                            <option value="null" selected>{{ this._LangStatic[0][this.props][0].sektor }} </option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </select>
                        </li>
                        <li class="list-group-item">
                          <select class="form-select is-invalid text-capitalize" aria-label="Default select example">
                            <option value="null" selected>{{ this._LangStatic[0][this.props][0].kategori }} </option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </select>
                        </li>
                        <li class="list-group-item d-flex justify-content-end">
                          <button name="filter2" class="btn btn-outline-warning text-capitalize" disabled>{{
                            this._LangStatic[0][this.props][0].filtrele }} </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!--FİLTER CANVAS />-->
              <div id="urunitems" class="card-body">
                <div class="row row-cols-1 row-cols-sm-1 row-cols-md-2 g-4">
                  <div class="col">
                    <div class="card mb-3">
                      <div class="row g-0">
                        <div class="col-sm-4 col-4 d-flex align-items-center">
                          <img data-item-src="urun35.jpg" data-img-urun="2" class="img-fluid rounded-start" alt="...">
                          <i @click="DialogShowİmg(2)" class="fa-solid fa-magnifying-glass-plus"></i>
                        </div>
                        <div class="col-sm-8 col-8">
                          <div data-ayrinti-urun="1" class="card-body">
                            <h5 class="card-title text-capitalize">mobilya malzemesi
                            </h5>
                            <p class="card-text text-capitalize text-start text-muted">
                              Lorem ipsum dolor sit amet consectetur adipisicing elit.
                              Ea voluptate voluptatum enim amet eligendi dolore
                              dolorum placeat quo nostrum libero? Consectetur ullam
                              inventore distinctio, quos voluptas accusamus quia
                              dignissimos corporis?
                            </p>
                            <p class="card-text d-flex justify-content-between">
                              <small class="text-muted text-capitalize">Last updated 3
                                mins ago</small>
                              <small @click="DialogShowAyrinti(2)" class="text-capitalize text-danger">ayrıntılı
                                incele...</small>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col">
                    <div class="card mb-3">
                      <div class="row g-0">
                        <div class="col-sm-4 col-4 d-flex align-items-center">
                          <img src="" data-item-src="urun2.jpg" data-img-urun="2" class="img-fluid rounded-start"
                            alt="...">
                          <i @click="DialogShowİmg(2)" class="fa-solid fa-magnifying-glass-plus"></i>
                        </div>
                        <div class="col-sm-8 col-8">
                          <div data-ayrinti-urun="1" class="card-body">
                            <h5 class="card-title text-capitalize">inşaat malzemesi
                            </h5>
                            <p class="card-text text-capitalize text-start text-muted">
                              Lorem ipsum dolor sit amet consectetur adipisicing elit.
                              Ea voluptate voluptatum enim amet eligendi dolore
                              dolorum placeat quo nostrum libero? Consectetur ullam
                              inventore distinctio, quos voluptas accusamus quia
                              dignissimos corporis?
                            </p>
                            <p class="card-text d-flex justify-content-between">
                              <small class="text-muted text-capitalize">Last updated 3
                                mins ago</small>
                              <small @click="DialogShowAyrinti(2)" class="text-capitalize text-danger">ayrıntılı
                                incele...</small>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col">
                    <div class="card mb-3">
                      <div class="row g-0">
                        <div class="col-sm-4 col-4 d-flex align-items-center">
                          <img src="" data-item-src="urun35.jpg" data-img-urun="2" class="img-fluid rounded-start"
                            alt="...">
                          <i @click="DialogShowİmg(2)" class="fa-solid fa-magnifying-glass-plus"></i>
                        </div>
                        <div class="col-sm-8 col-8">
                          <div data-ayrinti-urun="1" class="card-body">
                            <h5 class="card-title text-capitalize">inşaat malzemesi
                            </h5>
                            <p class="card-text text-capitalize text-start text-muted">
                              Lorem ipsum dolor sit amet consectetur adipisicing elit.
                              Ea voluptate voluptatum enim amet eligendi dolore
                              dolorum placeat quo nostrum libero? Consectetur ullam
                              inventore distinctio, quos voluptas accusamus quia
                              dignissimos corporis?
                            </p>
                            <p class="card-text d-flex justify-content-between">
                              <small class="text-muted text-capitalize">Last updated 3
                                mins ago</small>
                              <small @click="DialogShowAyrinti(2)" class="text-capitalize text-danger">ayrıntılı
                                incele...</small>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col">
                    <div class="card mb-3">
                      <div class="row g-0">
                        <div class="col-sm-4 col-4 d-flex align-items-center">
                          <img src="" data-item-src="urun1.jpg" data-img-urun="2" class="img-fluid rounded-start"
                            alt="...">
                          <i @click="DialogShowİmg(2)" class="fa-solid fa-magnifying-glass-plus"></i>
                        </div>
                        <div class="col-sm-8 col-8">
                          <div data-ayrinti-urun="1" class="card-body">
                            <h5 class="card-title text-capitalize">inşaat malzemesi
                            </h5>
                            <p class="card-text text-capitalize text-start text-muted">
                              Lorem ipsum dolor sit amet consectetur adipisicing elit.
                              Ea voluptate voluptatum enim amet eligendi dolore
                              dolorum placeat quo nostrum libero? Consectetur ullam
                              inventore distinctio, quos voluptas accusamus quia
                              dignissimos corporis?
                            </p>
                            <p class="card-text d-flex justify-content-between">
                              <small class="text-muted text-capitalize">Last updated 3
                                mins ago</small>
                              <small @click="DialogShowAyrinti(2)" class="text-capitalize text-danger">ayrıntılı
                                incele...</small>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </aside>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
// import Swiper core and required modules
import { mapGetters } from 'vuex';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
const $ = require("jquery")
export default {
  name: 'HomeView',
  data() {
    return {
      ShowAyrintiDialog: null,
      ShowİmgDialog: null,
      props: "tr",
    }
  },

  methods: {
    DialogShowİmg(data) {
      if (data) this.ShowİmgDialog = data
    },
    DialogHidwİmg() {
      this.ShowİmgDialog = false
    },
    DialogShowAyrinti(data) {
      if (data) {
        this.ShowAyrintiDialog = data
      }
    },
    DialogHideAyrinti() {
      this.ShowAyrintiDialog = false
    },
    BilgiAlert(e) {
      $(e.target).closest("div.alert").addClass("d-none");
      
    },
  },
  beforeUpdate() {
    const newHtmlLang = $("html").attr("lang")
    this.props = newHtmlLang
  },
  computed: {
    ...mapGetters(['_LangStatic', '_urunler'])
  },
  components: {
    Swiper,
    SwiperSlide,
    // Swiper Vue.js
  },
  mounted() {
    const options = {
      //root: document.querySelector("#slayd_urun_hizmetler"), 
      //rootMargin: "-150px 0px",
      treshold: 0.8
    }
    const callback = entries => {
      const firstEntry = entries[0];
      if (firstEntry.isIntersecting) {
        const SrcChangeItem = firstEntry.target.dataset.itemSrc
        const ChangeItemData = require(`@/assets/img/urun_img/${String(SrcChangeItem)}`)
        $(firstEntry.target).attr("src", ChangeItemData)
      }
    }
    const observer = new IntersectionObserver(callback, options);

    const coolDiv = document.querySelectorAll("[data-item-src]");
    coolDiv.forEach(v => {
      observer.observe(v);
    })
    $(() => {
      const divPosition = $("#slayd_urun_hizmetler").position();
      var top = divPosition.top;
      if (top > 0) $(window).scrollTop(5);
    })
  },
  setup() {
    return {
      // Swiper Vue.js
      modules: [Autoplay, Pagination, Navigation],
      // Swiper Vue.js  
    }
  },
}

</script>








