
import { createStore } from 'vuex'
// const $ = require("jquery")
export default createStore({
  state: {
    youtubeVideo: [
      {
        video: [
          ["https://i.ytimg.com/vi/8kO795s1rJQ/default.jpg", "8kO795s1rJQ", "Hizmetlerimiz...", "23 Eyl 2023"],
          ["https://i.ytimg.com/vi/0Z5WL0bJpMA/default.jpg", "0Z5WL0bJpMA", "Biz Kimiz...", "23 Kas 2023"]
        ],
        updateDate: ["1685644838012"]
      }
    ],
    urunler: [
      {
        _id: "64eba8bc999fb1dd2a35b5d5",
        uruncheck: true,
        userid: "64ad58c4c6a2a92d53a75f10",
        urunhizmet: false,
        satisalis: false,
        urunadi: [
          {
            tr: "lojistik hizmeti",
            en: "logistics service",
            fr: "service logistique",
            ar: "الخدمة اللوجستية",
            es: "servicio logístico",
            ru: "логистическая служба",
          }
        ],
        aciklama: [
          {
            tr: "türkiye'm avrupalıların her yerine tir taşımacılığı yapiyoruz",
            en: "we do truck transport to all parts of turkey, europeans",
            fr: "nous effectuons le transport par camion dans toutes les régions de la …",
            ar: "نقوم بالنقل بالشاحنات لجميع أنحاء تركيا والأوروبيين",
            es: "hacemos transporte de camiones a todas partes de turquía, europeos.",
            ru: "осуществляем автомобильные перевозки во все части турции, европейцы",
          }
        ],
        ulke: "DE",
        sektor: "6476742b10c20c78a434f352",
        kategori: "64788ecdacbfeeb489704d2d",
        miktar: 200,
        serino: "yesnonoyes38123123123",
        imgsrc: "urun1.jpg", //"yesnonoyes38-1689147141730.jpg", database kayıtlı olan
        tiklanmaSayisi: 0,
        createDate: "2023-08-27T19:49:16.818+00:00",
        updateAt: "2023-08-27T19:49:16.818+00:00",
      },
      {
        _id: "64eba8bc999fb1dd2a35b646",
        uruncheck: true,
        userid: "64ad58c4c6a2a92d53a75f11",
        urunhizmet: false,
        satisalis: false,
        urunadi: [
          {
            tr: "ulaştırma hizmeti",
            en: "logistics service",
            fr: "service logistique",
            ar: "الخدمة اللوجستية",
            es: "servicio logístico",
            ru: "логистическая служба",
          }
        ],
        aciklama: [
          {
            tr: "türkiye'm avrupalıların her yerine tir ulaştırması yapiyoruz",
            en: "we do truck transport to all parts of turkey, europeans",
            fr: "nous effectuons le transport par camion dans toutes les régions de la …",
            ar: "نقوم بالنقل بالشاحنات لجميع أنحاء تركيا والأوروبيين",
            es: "hacemos transporte de camiones a todas partes de turquía, europeos.",
            ru: "осуществляем автомобильные перевозки во все части турции, европейцы",
          }
        ],
        ulke: "AR",
        sektor: "6476742b10c20c78a434f352",
        kategori: "64788ecdacbfeeb489704d2d",
        miktar: 200,
        serino: "yesnonoyes38123123123",
        imgsrc: "urun2.jpg", //"yesnonoyes38-1689147141730.jpg", database kayıtlı olan
        tiklanmaSayisi: 0,
        createDate: "2023-08-27T19:49:16.818+00:00",
        updateAt: "2023-08-27T19:49:16.818+00:00",
      },
    ],

    LangStatic: [
      {
        "tr": [
          {
            iletisim: "iletişim",
            dilsec: "dil seç",
            turkce: "türkçe",
            ingilizce: "ingilizce",
            arapca: "arapça",
            fransizca: "fransizca",
            ispanyolca: "ispanyolca",
            rusca: "rusça",
            girishatali: "giriş hatalı",
            nasilcalisir: "nasıl çalışır",
            nasilcalisirmetin1: "Sanal fuar projesi yeni dünya düzenine uygun olarak tasarlanmıştır.Platformüzerinde satıcı ve alıcı işletmeler ve brokerler yer almaktadır.Üretici firmalar ürünlerini sanal fuar platformu üzerinde yüksek maliyet ödemedenpaylaşır.Sanal fuar platformu üzerinde yer alan firmalar, E- exportcity ileürünlerini 7 / 24 dünya genelinde sergileme fırsatı bulursunuz.Buplatformdaki üretici firmalar ürünlerini hızlı şekilde dünyanın her yerinesunmaktadır.Bu sayede, işletmeler ve brokerler işlemlerini online olarakkolaylıkla yapma hakkına sahip olurlar.Aynı zamanda, üretici firmalar,sanal fuar platformu üzerinden broker sisteminden faydalanırlar.",
            nasilcalisirmetin2: "Dış ticaret, satış, pazarlama ile ilgili ihtiyaçları ve yurtdışı seyahatlerinizin, maliyetleri minimum seviyeye indirebilir. Üretici firmaların platform üzerindeki ürünlerini çok daha kısa yoldan ve uygun maliyetlerle satma olanağı sunarlar. Alıcı firmalar, sisteme üye olduktan sonra alım talebi oluşturarak üretici firmalara ya da broker bağlantılarıyla, daha fazla satıcı firmaya ulaşır. Bu sayede, hızlı ve güvenilir şekilde ürünlerini ulaştırmaktadır. Sanal fuar platformu sistemi sayesinde çok daha karlı şekilde satış işlemleri gerçekleşmektedir.",
            brokersistemi: "broker sistemi",
            brokersistemimetin1: "Broker sistemi, herhangi bir firmada ya da şirkette maaşlı olarak çalışmak istemeyen kişiler için sunulan bir fırsattır. İster evinizde isterseniz tatil yerinde freelance olarak çalışma imkanı sunmaktadır. Online iş modeli olan bu platform, üretici ve alıcı firma arasında gerçekleşmektedir. Müşteri ve satıcılara aracılık yaparak komisyonlu satış şeklinde kazanç elde edilmektedir.",
            brokersistemimetin2: "Broker sistemi, üretici firmalara herhangi bir maliyet oluşturmaksızın ülkeler arasında firma adına, satış ve pazarlama hizmeti vermektedir. Bu sayede, üretici işletmelerin hızlı şekilde daha çok müşteriye ulaşılmasına olanak sunarlar. Ayrıca, alıcı işletmelerin dünyanın birçok yerine hızlı bir şekilde ürünlere ulaşması sağlanır. Brokerlik sistemi ile dünya genelindeki bütün firmaların reklam, pazarlama ve personel giderlerini ortadan kaldırmaktadır. Broker sisteminde satış yapıldığı takdirde komisyon sistemi ile brokerlerin yüksek kazanç elde etmesini sağlar. Dolayısıyla, kazandıran ve kazanan bir sistemin parçası olurlar.",
            brokersistemimetin3: "Broker sistem üzerinde yer alan ülkelerle karşılıklı iş birliği yaparak, üretici ve alıcı firmalarla bağlantı kurulan platform üzerinde satış talebi oluşturulur. Platform üzerinde aracı brokerler, alım taleplerine yanıt vererek, ithalat ve ihracatlara hız kazandırmaktadır. Böylece, daha hızlı ticaret ortamının oluşması sağlanır. Platform üzerinde yer alan alıcı ve satıcılar arasında davet kodu ile bağlantı kurulmaktadır. Yeni brokerleri davet kodu ile bağlantı kurulduğunda bütün üyeler belirli bir ücret komisyonu kazanmaktadır. Brokerler tarafından davet edilen ve platforma üye yapılan firmalardan düzenli ödeme almaya devam ederler.",
            reklamvermek: "reklam vermek",
            reklamvermekmetin1: "hazırlanacaktır.",
            guvenlik: "güvenlik",
            guvenlikmetin1: "E-exportcity dünyanın dört bir yanındaki ihracat ve ithalat firmalarını buluşturan bir ticaret platformudur. Firmaların güvenliğini koruyarak güvenilir ticaret faaliyetlerini yürütmektedir. İşletmelerin sorunlarını ortadan kaldırmak üzere geliştirilmiştir. Aynı zamanda, E-exportcity platformu içerisinde faaliyette bulunan firmaların dolandırılma olasılığını engelleme görevini üstlenmiştir. Platform üzerinde faaliyetlerini sürdüren işletmeler ve brokerler ticaret yapmak için ürün ve hizmet talebini oluşturur. Sektörel pazarlama alanından, pazar araştırmalarına varana kadar bütün işlemlerinizi gerçekleştirmenize olanak sunar. Dünya üzerinde kıtalar arası ithalat ve ihracat işlemlerini gerçekleştirmek için, güvenliticaret@e-exportcity.com mail adresiyle iletişime geçebilirsiniz. Konuyla ilgili ekiplerimizden destek alabilirsiniz. Alanında uzman ekiplerimiz işletmenizi dolandırılma ve maddi zarara karşı koruyacak önlemler almaktadır.",
            guvenlikmetin2: "Global çapta adını duyurmak isteyen firmaların tercih ettiği platforma üye olarak ilk adımı atabilirsiniz. E-exportcity ailesine üye olduktan sonra sizleri bütün gelişmelerden haberdar etmek için bildirim gönderilecektir. Doğrudan alım talepleriyle ilgili bilgileri içeren en doğru kaynakları birinci elden öğrenerek, markanızı dünya çapına tanıtma fırsatınız olur. E-exportcity platformu online olarak dünya genelinde hizmet vermek üzere, 7/24 çalışmalarına devam etmektedir. Deneyimli uzman kadromuzla sizlerin sorunlarını en hızlı şekilde cevap verir. Dünya çapındaki birçok ülkeye tek bir tıkla otomotiv, tekstil, kozmetik ve gayrimenkul gibi sektörlerin buluştuğu platform müşterilerini beklemektedir.",
            temsilcilik: "temsilcilik",
            temsilcilikmetin1: "E-exportcity platformunda temsilci olmak isterseniz, sisteme üye olmalısınız. Üye olurken broker bölümünü kullanarak üyelik işlemlerinizi gerçekleştirebilirsiniz. Bu platformda üyelik paket seçeneklerinden yararlanarak ticaret yapabilirsiniz. Ürünlerle ilgili alış ve satış talebi oluşturabilir ve talepleri sistem üzerinde görüntüleyebilirsiniz. Böylece, bağımsız olarak platformda ticaret yaparak kazanç elde edebilirsiniz. E-exportcity platformunun size sunduğu diğer hizmetler şu şekildedir. Temsilcilik sistemi ile temsilci olmak için üye olduktan sonra size tanımlanan referans kodu ile ticaret yapabilirsiniz. Çevrenizi, arkadaşlarınızı ya da işletmeleri sisteme davet ederek kazanç elde edebilirsiniz. Biliyorsunuz ki, günümüzde artık herkes kendi işini kurup işinin patronu olmak istemektedir. E-exportcity platformu, isteyen herkese kazanç elde edebilecekleri pazar alanı sunar.",
            temsilcilikmetin2: "Temsilci olarak sitemizin ana sayfasında yer almak için sisteme en az, 250 broker ya da firma davet etmeniz gerekir. Üye yaptığınız brokerler ve işletmeler sisteme üye oldukları süre boyunca aylık düzenli olarak dolar kazanma fırsatı elde eder. 250 üyeye ulaştığınızda temsilci olma imkanınız vardır. Aynı zamanda, sürekli olacak bir kazanç elde edebilirsiniz. Davet ettiğiniz temsilci sayısı 250 üyenin altında kalırsa tek seferlik bir ödeme alırsınız. Sisteme dahil edeceğiniz her üye için 1$ kazanacaksınız. Sisteme 100 üye yaptınız taktirde tek seferlik 100$ ödeme alırsınız. Sisteme üye yaptığınız işletmelerin sayısı 250 ve üzeri olduğunda E-exportcity platformunda yer alırsınız. Ayrıca, sürekli olarak kazanç elde edersiniz.",
            ensoneklenenler: "en son eklenenler",
            urunlerhizmetler: "ürünler hizmetler",
            urunayrintilari: "ürün ayrıntıları",
            sektor: "sektör",
            kategori: "kategori",
            miktar: "miktar",
            seri: "seri",
            tarih: "tarih",
            acıklama: "açıklama",
            firmabilgisigor: "firma bilgisi gör",
            satis: "satış",
            filtreleme: "filtreleme",
            secimyapiniz: "seçim yapınız",
            urunserinumarasi: "ürün seri numarası",
            urun: "ürün",
            hizmet: "hizmet",
            talep: "talep",
            ulke: "ulke",
            filtrele: "filtrele",
            duyurular: "duyurular",
            urunekle: "ürün ekle",
            reklamekle: "reklam ekle",
            urunadi: "ürün adı",
            kaydet: "kaydet",
            resimekle: "resim ekle",
            urunaciklamasi: "ürün açıklaması",
            baslik: "başlık",
            altbaslik: "alt başlık",
            epostaadresi: "e posta adresi",
            sifre: "şifre",
            girisbasarili: "giriş başarılı",
            giris: "giriş",
          }
        ],
        "en": [
          {
            iletisim: "communication",
            dilsec: "choose language",
            turkce: "turkısh",
            ingilizce: "english",
            arapca: "Arabic",
            fransizca: "French",
            ispanyolca: "Spanish",
            rusca: "Russian",
            girishatali: "entry is incorrect",
            nasilcalisir: "How does it work",
            nasilcalisirmetin1: "The virtual fair project is designed in accordance with the new world order. There are seller and buyer businesses and brokers on the platform. Manufacturers share their products on the virtual fair platform without paying high costs. Companies on the virtual fair platform have the opportunity to exhibit their products around the world 24/7 with E-exportcity. Manufacturers on this platform offer their products quickly to all over the world. In this way, businesses and brokers have the right to easily carry out their transactions online. At the same time, manufacturers benefit from the broker system through the virtual fair platform.",
            nasilcalisirmetin2: "Foreign trade, sales, marketing related needs and international It can minimize the costs of your travels. Producer companies can offer their products on the platform in a much shorter and more convenient way. They offer the opportunity to sell at lower costs. Once the buyer companies become members of the system, Then, by creating a purchase request, you can send it to manufacturers or brokers. With its connections, it reaches more vendors. In this way, fast and delivers its products reliably. Virtual fair platform system Thanks to this, sales transactions are carried out much more profitably.",
            brokersistemi: "broker system",
            brokersistemimetin1: "The broker system is an opportunity offered to people who do not want to work on a salaried basis in any firm or company. It offers the opportunity to work as a freelancer, either at home or at a holiday destination. This platform, which is an online business model, takes place between the manufacturer and the buyer company. Earnings are earned in the form of commission sales by acting as an intermediary for customers and sellers.",
            borkersistemimetin2: "The broker system provides sales and marketing services on behalf of the company across countries without creating any cost to the manufacturing companies. In this way, they enable manufacturers to reach more customers quickly. In addition, buyer businesses are provided with rapid access to products to many parts of the world. With its brokerage system, it eliminates the advertising, marketing and personnel expenses of all companies around the world. If sales are made in the broker system, the commission system allows brokers to earn high profits. Therefore, they become part of a profitable and winning system.",
            brokersistemimetin3: "By cooperating with the countries on the broker system, a sales demand is created on the platform where producers and buyers are connected. Intermediary brokers on the platform respond to purchasing requests and accelerate imports and exports. Thus, a faster trading environment is created. A connection is established between buyers and sellers on the platform with an invitation code. All members earn a certain fee commission when new brokers are connected with the invitation code. They continue to receive regular payments from companies invited by brokers and made members of the platform.",
            reklamvermek: "To advertise",
            reklamvermekmetin1: "will be prepared.",
            guvenlik: "security",
            guvenlikmetin1: "E-exportcity is a trading platform that brings together export and import companies from around the world. It carries out reliable trade activities by protecting the security of companies. It was developed to eliminate the problems of businesses. At the same time, it has undertaken the task of preventing the possibility of fraud of companies operating within the E-exportcity platform. Businesses and brokers that operate on the platform create demand for products and services for trading. It allows you to carry out all your transactions, from sectoral marketing to market research. To carry out intercontinental import and export transactions around the world, you can contact the e-mail address bilgiticaret@e-exportcity.com. You can get support from our teams on the subject. Our expert teams take precautions to protect your business against fraud and financial loss.",
            guvenlikmetin2: "You can take the first step by becoming a member of the platform preferred by companies that want to make a name for themselves on a global scale. After you become a member of the e-exportcity family, a notification will be sent to keep you informed of all developments. By learning first-hand the most accurate sources containing information regarding direct purchase requests, you have the opportunity to promote your brand worldwide. E-exportcity platform continues its operations 24/7 to provide online services worldwide. Our experienced expert staff responds to your problems as quickly as possible. The platform, where sectors such as automotive, textile, cosmetics and real estate meet with a single click, awaits its customers in many countries around the world.",
            temsilcilik: "representation",
            temsilcilikmetin1: "If you want to be a representative on the e-exportcity platform, you must become a member of the system. You can carry out your membership transactions by using the broker section while becoming a member. You can trade on this platform by taking advantage of membership package options. You can create buying and selling requests for products and view the requests on the system. Thus, you can independently earn profits by trading on the platform. Other services offered by the e-exportcity platform are as follows. After you become a member of the representative system, you can trade with the reference code defined for you. You can earn money by inviting your circle, friends or businesses to the system. As you know, nowadays everyone wants to start their own business and be the boss of their business. E-exportcity platform offers a market place where everyone can earn money.",
            temsilcilikmetin2: "In order to be on the home page of our site as a representative, you must invite at least 250 brokers or companies to the system. The brokers and businesses you become members of have the opportunity to earn regular dollars monthly while they are members of the system. When you reach 250 members, you have the opportunity to become a representative. At the same time, you can earn a steady income. If the number of delegates you invite remains below 250 members, you will receive a one-time payment. You will earn $1 for each member you add to the system. If you sign up 100 members to the system, you will receive a one-time payment of $100. When the number of businesses you have subscribed to the system is 250 or more, you will be on the E-exportcity platform. Plus, you get consistent profits.",
            ensoneklenenler: "recently added",
            urunlerhizmetler: "products services",
            urunayrintilari: "product details",
            sektor: "sector",
            kategori: "category",
            miktar: "amount",
            seri: "sherry",
            tarih: "history",
            acıklama: "explanation",
            satis: "sales",
            firmabilgisigor: "see company information",
            filtreleme: "filtering",
            secimyapiniz: "your choice",
            urunserinumarasi: "product seri number",
            urun: "product",
            hizmet: "service",
            talep: "request",
            ulke: "country",
            filtrele: "filter",
            duyurular: "announcements",
            urunekle: "add product",
            reklamekle: "add ad",
            urunadi: "Name of the product",
            kaydet: "save",
            resimekle: "add picture",
            urunaciklamasi: "Product Description",
            baslik: "title",
            altbaslik: "subtitle",
            epostaadresi: "E-mail address",
            sifre: "password",
            girisbasarili: "Login successful",
            giris: "entrance",
          }
        ],
        "fr": [
          {
            iletisim: "communication",
            dilsec: "Choisissez la langue",
            turkce: "turc",
            ingilizce: "Anglais",
            arapca: "arabe",
            fransizca: "Français",
            ispanyolca: "Espagnol",
            rusca: "russe",
            girishatali: "l'entrée est incorrecte",
            nasilcalisir: "Comment ça marche",
            nasilcalisirmetin1: "Le projet de salon virtuel est conçu conformément au nouvel ordre mondial. Il y a des entreprises et des courtiers vendeurs et acheteurs sur la plateforme. Les fabricants partagent leurs produits sur la plateforme de salon virtuel sans payer de frais élevés. Les entreprises sur la plateforme de salon virtuel ont la possibilité de Exposez leurs produits dans le monde entier 24h/24 et 7j/7 avec E-exportcity. Les fabricants sur cette plateforme proposent leurs produits rapidement partout dans le monde. De cette manière, les entreprises et les courtiers ont le droit d'effectuer facilement leurs transactions en ligne. , les fabricants bénéficient du système de courtage via la plateforme du salon virtuel.",
            nasilcalisirmetin2: "Commerce extérieur, ventes, besoins liés au marketing et international Cela peut minimiser les coûts de vos déplacements. Producteur les entreprises peuvent proposer leurs produits sur la plateforme de manière beaucoup plus courte et plus pratique. Ils offrent la possibilité de vendre à moindre coût. Une fois que les entreprises acheteuses deviennent membres du système, Ensuite, en créant une demande d’achat, vous pourrez l’envoyer aux fabricants ou aux courtiers. Grâce à ses connexions, il atteint davantage de fournisseurs. De cette façon, rapide et livre ses produits de manière fiable. Système de plateforme de salon virtuel Grâce à cela, les transactions de vente sont réalisées de manière beaucoup plus rentable.",
            brokersistemi: "système de courtage",
            brokersistemimetin1: "Le système de courtage est une opportunité offerte aux personnes qui ne souhaitent pas travailler de manière salariée dans une entreprise ou une entreprise. Il offre la possibilité de travailler en tant qu'indépendant, soit à domicile, soit sur un lieu de vacances. Cette plateforme, qui est un modèle économique en ligne, s'effectue entre le fabricant et l'entreprise acheteuse. Les revenus sont gagnés sous forme de commissions sur les ventes en agissant en tant qu'intermédiaire pour les clients et les vendeurs.",
            borkersistemimetin2: "Le système de courtage fournit des services de vente et de marketing au nom de l'entreprise entre les pays sans créer de coûts pour les entreprises manufacturières. Ils permettent ainsi aux fabricants d’atteindre rapidement davantage de clients. En outre, les entreprises acheteuses bénéficient d’un accès rapide aux produits dans de nombreuses régions du monde. Avec son système de courtage, elle élimine les dépenses de publicité, de marketing et de personnel de toutes les entreprises du monde. Si les ventes sont réalisées via le système de courtage, le système de commission permet aux courtiers de réaliser des bénéfices élevés. Ils font donc partie d’un système rentable et gagnant.",
            brokersistemimetin3: "En coopérant avec les pays sur le système de courtage, une demande de vente est créée sur la plateforme où les producteurs et les acheteurs sont connectés. Les courtiers intermédiaires de la plateforme répondent aux demandes d'achat et accélèrent les importations et les exportations. Ainsi, un environnement commercial plus rapide est créé. Une connexion est établie entre acheteurs et vendeurs sur la plateforme avec un code d'invitation. Tous les membres gagnent une certaine commission lorsque de nouveaux courtiers sont connectés avec le code d'invitation. Ils continuent de recevoir des paiements réguliers des entreprises invitées par les courtiers et devenues membres de la plateforme.",
            reklamvermek: "Faire de la publicité",
            reklamvermekmetin1: "sera préparé.",
            guvenlik: "sécurité",
            guvenlikmetin1: "E-exportcity est une plateforme commerciale qui regroupe des entreprises d’exportation et d’importation du monde entier. Elle exerce des activités commerciales fiables en protégeant la sécurité des entreprises. Il a été développé pour éliminer les problèmes des entreprises. Dans le même temps, il s'est donné pour mission de prévenir les possibilités de fraude des entreprises opérant au sein de la plateforme E-exportcity. Les entreprises et les courtiers qui opèrent sur la plateforme créent une demande de produits et de services destinés au trading. Il vous permet de réaliser toutes vos transactions, du marketing sectoriel jusqu'aux études de marché. Pour effectuer des transactions d'importation et d'exportation intercontinentales à travers le monde, vous pouvez contacter l'adresse e-mail bilgiticaret@e-exportcity.com. Vous pouvez bénéficier de l’accompagnement de nos équipes sur le sujet. Nos équipes d’experts prennent des précautions pour protéger votre entreprise contre la fraude et les pertes financières.",
            guvenlikmetin2: "Vous pouvez faire le premier pas en devenant membre de la plateforme privilégiée par les entreprises qui souhaitent se faire un nom à l’échelle mondiale. Une fois devenu membre de la famille e-exportcity, une notification vous sera envoyée pour vous tenir informé de tous les développements. En apprenant directement les sources les plus précises contenant des informations concernant les demandes d'achat direct, vous avez la possibilité de promouvoir votre marque dans le monde entier. La plateforme E-exportcity poursuit ses opérations 24h/24 et 7j/7 pour fournir des services en ligne dans le monde entier. Notre personnel expert et expérimenté répond à vos problèmes dans les plus brefs délais. La plateforme, où se rencontrent en un seul clic des secteurs tels que l'automobile, le textile, la cosmétique et l'immobilier, attend ses clients dans de nombreux pays du monde.",
            temsilcilik: "représentation",
            temsilcilikmetin1: "Si vous souhaitez être représentant sur la plateforme e-exportcity, vous devez devenir membre du système. Vous pouvez effectuer vos transactions d'adhésion en utilisant la section courtier tout en devenant membre. Vous pouvez échanger sur cette plateforme en profitant des options de forfaits d’adhésion. Vous pouvez créer des demandes d'achat et de vente de produits et afficher les demandes sur le système. Ainsi, vous pouvez réaliser des bénéfices de manière indépendante en négociant sur la plateforme. Les autres services proposés par la plateforme e-exportcity sont les suivants. Après être devenu membre du système représentatif, vous pouvez négocier avec le code de référence défini pour vous. Vous pouvez gagner de l'argent en invitant votre cercle, vos amis ou vos entreprises dans le système. Comme vous le savez, de nos jours tout le monde veut créer sa propre entreprise et être le patron de son entreprise. La plateforme E-exportcity offre une place de marché où chacun peut gagner de l'argent.",
            temsilcilikmetin2: "Afin d'être sur la page d'accueil de notre site à titre de représentant, vous devez inviter au moins 250 courtiers ou entreprises dans le système. Les courtiers et les entreprises dont vous devenez membre ont la possibilité de gagner des dollars réguliers chaque mois pendant qu'ils sont membres du système. Lorsque vous atteignez 250 membres, vous avez la possibilité de devenir représentant. En même temps, vous pouvez gagner un revenu stable. Si le nombre de délégués que vous invitez reste inférieur à 250 membres, vous recevrez un paiement unique. Vous gagnerez 1 $ pour chaque membre que vous ajoutez au système. Si vous inscrivez 100 membres au système, vous recevrez un paiement unique de 100 $. Lorsque le nombre d’entreprises que vous avez inscrites au système est de 250 ou plus, vous serez sur la plateforme E-exportcity. De plus, vous obtenez des bénéfices constants.",
            ensoneklenenler: "récemment ajouté",
            urunlerhizmetler: "Produits",
            urunayrintilari: "détails du produit",
            sektor: "secteur",
            kategori: "catégorie",
            miktar: "montant",
            seri: "Sherry",
            tarih: "histoire",
            acıklama: "explication",
            satis: "ventes",
            firmabilgisigor: "voir les informations de l'entreprise",
            filtreleme: "filtration",
            secimyapiniz: "fais ton choix",
            urunserinumarasi: "numero de serie",
            urun: "produit",
            hizmet: "service",
            talep: "demande",
            ulke: "pays",
            filtrele: "filtre",
            duyurular: "annonces",
            urunekle: "ajouter un produit",
            reklemekle: "ajouter une annonce",
            urunadi: "Nom du produit",
            kaydet: "sauvegarder",
            resimekle: "Ajouter une image",
            urunaciklamasi: "Description du produit",
            baslik: "titre",
            altbaslik: "Sous-titre",
            epostaadresi: "Adresse e-mail",
            sifre: "mot de passe",
            girisbasarili: "Connexion réussie",
            giris: "entrée",
          }
        ],
        "ar": [
          {
            iletisim: "تواصل",
            dilsec: "اختر اللغة",
            turkce: "اللغة التركية",
            ingilizce: "إنجليزي",
            arapca: "عربي",
            fransizca: "فرنسي",
            ispanyolca: "الأسبانية",
            rusca: "الروسية",
            girishatali: "الإدخال غير صحيح",
            nasilcalisir: "كيف يعمل",
            nasilcalisirmetin1: "تم تصميم مشروع المعرض الافتراضي بما يتوافق مع النظام العالمي الجديد، حيث يوجد شركات ووسطاء بائعين ومشترين على المنصة، ويشارك المصنعون منتجاتهم على منصة المعرض الافتراضي دون دفع تكاليف عالية، وتتاح للشركات الموجودة على منصة المعرض الافتراضي فرصة يعرضون منتجاتهم حول العالم على مدار 24 ساعة طوال أيام الأسبوع مع E-exportcity. يقدم المصنعون على هذه المنصة منتجاتهم بسرعة إلى جميع أنحاء العالم. وبهذه الطريقة، يحق للشركات والوسطاء تنفيذ معاملاتهم عبر الإنترنت بسهولة. وفي نفس الوقت ويستفيد المصنعون من نظام الوسيط من خلال منصة المعرض الافتراضية.",
            nasilcalisirmetin2: "التجارة الخارجية والمبيعات والاحتياجات المتعلقة بالتسويق والدولية يمكن أن يقلل من تكاليف رحلاتك. منتج يمكن للشركات عرض منتجاتها على المنصة بطريقة أقصر بكثير وأكثر ملاءمة. أنها توفر الفرصة للبيع بتكاليف أقل. بمجرد أن تصبح الشركات المشترية أعضاء في النظام، وبعد ذلك، من خلال إنشاء طلب شراء، يمكنك إرساله إلى المصنعين أو الوسطاء. ومن خلال اتصالاتها، تصل إلى المزيد من البائعين. بهذه الطريقة، سريع و تقدم منتجاتها بشكل موثوق. نظام منصة المعرض الافتراضي وبفضل هذا، يتم تنفيذ معاملات البيع بشكل أكثر ربحية.",
            brokersistemi: "نظام الوسيط",
            brokersistemimetin1: "يعد نظام الوسيط فرصة متاحة للأشخاص الذين لا يرغبون في العمل على أساس راتب في أي شركة أو شركة. فهو يوفر الفرصة للعمل كمستقل، سواء في المنزل أو في مكان لقضاء العطلات. هذه المنصة، وهي نموذج أعمال عبر الإنترنت، تتم بين الشركة المصنعة والشركة المشترية. يتم تحقيق الأرباح في شكل مبيعات بالعمولة من خلال العمل كوسيط للعملاء والبائعين.",
            borkersistemimetin2: "يقدم نظام الوسيط خدمات البيع والتسويق نيابة عن الشركة عبر الدول دون خلق أي تكلفة على شركات التصنيع. وبهذه الطريقة، فإنها تمكن الشركات المصنعة من الوصول إلى المزيد من العملاء بسرعة. بالإضافة إلى ذلك، يتم تزويد الشركات المشترية بإمكانية الوصول السريع إلى المنتجات في العديد من أنحاء العالم. ومن خلال نظام الوساطة الخاص بها، فإنها تتخلص من نفقات الإعلان والتسويق والموظفين لجميع الشركات حول العالم. إذا تمت المبيعات في نظام الوسيط، فإن نظام العمولة يتيح للوسطاء تحقيق أرباح عالية. لذلك، يصبحون جزءًا من نظام مربح وفائز.",
            brokersistemimetin3: "من خلال التعاون مع الدول على نظام الوسيط، يتم إنشاء طلب المبيعات على المنصة حيث يتم ربط المنتجين والمشترين. يقوم الوسطاء الوسطاء على المنصة بالرد على طلبات الشراء وتسريع عمليات الاستيراد والتصدير. وبالتالي، يتم إنشاء بيئة تداول أسرع. يتم إنشاء اتصال بين المشترين والبائعين على المنصة باستخدام رمز الدعوة. يحصل جميع الأعضاء على عمولة رسوم معينة عندما يتصل الوسطاء الجدد برمز الدعوة. يستمرون في تلقي مدفوعات منتظمة من الشركات التي دعاها الوسطاء وأعضاء المنصة.",
            reklamvermek: "للإعلان",
            reklamvermekmetin1: "وسيتم إعداد.",
            guvenlik: "حماية",
            guvenlikmetin1: "E-exportcity هي منصة تجارية تجمع بين شركات التصدير والاستيراد من جميع أنحاء العالم. وتقوم بأنشطة تجارية موثوقة من خلال حماية أمن الشركات. تم تطويره للقضاء على مشاكل الشركات. وفي الوقت نفسه، أخذت على عاتقها مهمة منع احتمالية الاحتيال على الشركات العاملة ضمن منصة مدينة التصدير الإلكترونية. الشركات والوسطاء الذين يعملون على المنصة يخلقون الطلب على المنتجات والخدمات للتداول. فهو يسمح لك بتنفيذ جميع معاملاتك، بدءًا من التسويق القطاعي وحتى أبحاث السوق. للقيام بمعاملات الاستيراد والتصدير العابرة للقارات حول العالم، يمكنك الاتصال على عنوان البريد الإلكتروني bilgiticaret@e-exportcity.com. يمكنك الحصول على الدعم من فرقنا حول هذا الموضوع. تتخذ فرق الخبراء لدينا الاحتياطات اللازمة لحماية عملك من الاحتيال والخسائر المالية.",
            guvenlikmetin2: "يمكنك اتخاذ الخطوة الأولى من خلال أن تصبح عضوًا في النظام الأساسي الذي تفضله الشركات التي ترغب في صنع اسم لنفسها على نطاق عالمي. بعد أن تصبح عضوًا في عائلة e-exportcity، سيتم إرسال إشعار لإطلاعك على كافة التطورات. من خلال التعرف بشكل مباشر على المصادر الأكثر دقة التي تحتوي على معلومات تتعلق بطلبات الشراء المباشرة، لديك الفرصة للترويج لعلامتك التجارية في جميع أنحاء العالم. تواصل منصة E-exportcity عملياتها على مدار الساعة طوال أيام الأسبوع لتقديم الخدمات عبر الإنترنت في جميع أنحاء العالم. يستجيب موظفونا الخبراء ذوو الخبرة لمشاكلك في أسرع وقت ممكن. وتنتظر المنصة، حيث تجتمع قطاعات مثل السيارات والمنسوجات ومستحضرات التجميل والعقارات بنقرة واحدة، عملائها في العديد من البلدان حول العالم.",
            temsilcilik: "التمثيل",
            temsilcilikmetin1: "إذا كنت تريد أن تكون ممثلاً في منصة مدينة التصدير الإلكترونية، فيجب أن تصبح عضوًا في النظام. يمكنك تنفيذ معاملات عضويتك باستخدام قسم الوسيط أثناء عضويتك. يمكنك التداول على هذه المنصة من خلال الاستفادة من خيارات حزمة العضوية. يمكنك إنشاء طلبات بيع وشراء للمنتجات وعرض الطلبات على النظام. وبالتالي، يمكنك تحقيق الأرباح بشكل مستقل عن طريق التداول على المنصة. الخدمات الأخرى التي تقدمها منصة e-exportcity هي كما يلي. بعد أن تصبح عضوا في النظام التمثيلي، يمكنك التداول باستخدام الرمز المرجعي المحدد لك. يمكنك كسب المال عن طريق دعوة دائرتك أو أصدقائك أو شركتك إلى النظام. كما تعلم، يرغب الجميع في الوقت الحاضر في بدء أعمالهم التجارية الخاصة وأن يصبحوا رئيس أعمالهم. توفر منصة E-exportcity سوقًا حيث يمكن للجميع كسب المال.",
            temsilcilikmetin2: "لكي تكون على الصفحة الرئيسية لموقعنا كممثل، يجب عليك دعوة ما لا يقل عن 250 وسيطًا أو شركة إلى النظام. يتمتع الوسطاء والشركات التي أصبحت أعضاء فيها بفرصة كسب دولارات منتظمة شهريًا أثناء عضويتهم في النظام. عندما تصل إلى 250 عضوا، لديك الفرصة لتصبح ممثلا. وفي نفس الوقت يمكنك الحصول على دخل ثابت. إذا ظل عدد المندوبين الذين تدعوهم أقل من 250 عضوًا، فستتلقى دفعة لمرة واحدة. سوف تكسب 1 دولار لكل عضو تضيفه إلى النظام. إذا قمت بتسجيل 100 عضو في النظام، فسوف تتلقى دفعة لمرة واحدة بقيمة 100 دولار. عندما يصل عدد الشركات التي اشتركت بها في النظام إلى 250 شركة أو أكثر، ستكون على منصة E-exportcity. بالإضافة إلى ذلك، يمكنك الحصول على أرباح متسقة.",
            ensoneklenenler: "أضيف مؤخرا",
            urunlerhizmetler: "المنتجات والخدمات",
            urunayrintilari: "تفاصيل المنتج",
            sektor: "قطاع",
            kategori: "فئة",
            miktar: "كمية",
            seri: "مدري",
            tarih: "تاريخ",
            acıklama: "توضيح",
            satis: "مبيعات",
            firmabilgisigor: "انظر معلومات الشركة",
            filtreleme: "الفلتره",
            secimyapiniz: "اصنع اختيارك",
            urunserinumarasi: "الرقم التسلسلي للمنتج",
            urun: "نتاج",
            hizmet: "خدمة",
            talep: "طلب",
            ulke: "دولة",
            filtrele: "منقي",
            duyurular: "الإعلانات",
            urunekle: "أضف منتج",
            reklamekle: "إضافة إعلان",
            urunadi: "اسم المنتج",
            kaydet: "يحفظ",
            resimekle: "إضافة الصورة",
            urunaciklamasi: "وصف المنتج",
            baslik: "عنوان",
            altbaslik: "العنوان الفرعي",
            epostaadresi: "عنوان البريد الالكتروني",
            sifre: "كلمة المرور",
            girisbasarili: "تم تسجيل الدخول بنجاح",
            giris: "مدخل",
          }
        ],
        "es": [
          {
            iletisim: "comunicación",
            dilsec: "elige lengua",
            turkce: "turco",
            ingilizce: "Inglés",
            arapca: "Arábica",
            fransizca: "Francés",
            ispanyolca: "Español",
            rusca: "ruso",
            girishatali: "la entrada es incorrecta",
            nasilcalisir: "Como funciona",
            nasilcalisirmetin1: "El proyecto de la feria virtual está diseñado de acuerdo con el nuevo orden mundial. En la plataforma hay empresas vendedoras, compradores y corredores. Los fabricantes comparten sus productos en la plataforma de la feria virtual sin pagar altos costos. Las empresas en la plataforma de la feria virtual tienen la oportunidad de exhiben sus productos en todo el mundo las 24 horas del día, los 7 días de la semana con E-exportcity. Los fabricantes en esta plataforma ofrecen sus productos rápidamente a todo el mundo. De esta manera, las empresas y corredores tienen derecho a realizar fácilmente sus transacciones en línea. Al mismo tiempo , los fabricantes se benefician del sistema de intermediación a través de la plataforma de feria virtual.",
            nasilcalisirmetin2: "Comercio exterior, ventas, necesidades relacionadas con marketing e internacional. Puede minimizar los costos de sus viajes. Productor las empresas pueden ofrecer sus productos en la plataforma de una forma mucho más breve y cómoda. Ofrecen la oportunidad de vender a menores costos. Una vez que las empresas compradoras se convierten en miembros del sistema, Luego, al crear una solicitud de compra, podrá enviarla a los fabricantes o intermediarios. Con sus conexiones, llega a más proveedores. De esta manera, rápido y entrega sus productos de manera confiable. Sistema de plataforma de feria virtual Gracias a ello, las transacciones de venta se realizan de forma mucho más rentable.",
            brokersistemi: "sistema de intermediario",
            brokersistemimetin1: "El sistema de intermediación es una oportunidad que se ofrece a las personas que no quieren trabajar de forma asalariada en ninguna firma o empresa. Ofrece la oportunidad de trabajar como autónomo, ya sea desde casa o en un destino de vacaciones. Esta plataforma, que es un modelo de negocio online, se desarrolla entre el fabricante y la empresa compradora. Las ganancias se obtienen en forma de ventas a comisión actuando como intermediario entre clientes y vendedores.",
            borkersistemimetin2: "El sistema de intermediario proporciona servicios de ventas y marketing en nombre de la empresa entre países sin generar ningún costo para las empresas fabricantes. De esta manera, permiten a los fabricantes llegar rápidamente a más clientes. Además, las empresas compradoras tienen acceso rápido a productos en muchas partes del mundo. Con su sistema de intermediación elimina los gastos de publicidad, marketing y personal de todas las empresas del mundo. Si las ventas se realizan en el sistema de corredores, el sistema de comisiones permite a los corredores obtener grandes ganancias. Por tanto, pasan a formar parte de un sistema rentable y ganador.",
            brokersistemimetin3: "Al cooperar con los países en el sistema de intermediarios, se crea una demanda de ventas en la plataforma donde se conectan productores y compradores. Los corredores intermediarios de la plataforma responden a las solicitudes de compra y aceleran las importaciones y exportaciones. De este modo, se crea un entorno comercial más rápido. Se establece una conexión entre compradores y vendedores en la plataforma con un código de invitación. Todos los miembros ganan una determinada comisión cuando se conectan nuevos corredores con el código de invitación. Continúan recibiendo pagos regulares de empresas invitadas por corredores y miembros de la plataforma.",
            reklamvermek: "para anunciar",
            reklamvermekmetin1: "Estará preparado.",
            guvenlik: "seguridad",
            guvenlikmetin1: "E-exportcity es una plataforma comercial que reúne a empresas exportadoras e importadoras de todo el mundo. Lleva a cabo actividades comerciales confiables protegiendo la seguridad de las empresas. Fue desarrollado para eliminar los problemas de las empresas. Al mismo tiempo, se ha dado a la tarea de prevenir la posibilidad de fraude de las empresas que operan dentro de la plataforma E-exportcity. Las empresas y corredores que operan en la plataforma crean demanda de productos y servicios para el comercio. Le permite realizar todas sus transacciones, desde marketing sectorial hasta estudios de mercado. Para realizar transacciones intercontinentales de importación y exportación en todo el mundo, puede contactar con la dirección de correo electrónico bilgiticaret@e-exportcity.com. Puede obtener apoyo de nuestros equipos sobre el tema. Nuestros equipos de expertos toman precauciones para proteger su negocio contra fraudes y pérdidas financieras.",
            guvenlikmetin2: "Puedes dar el primer paso haciéndote miembro de la plataforma preferida por las empresas que quieren hacerse un nombre a escala global. Después de convertirse en miembro de la familia e-exportcity, se enviará una notificación para mantenerlo informado de todos los avances. Al conocer de primera mano las fuentes más precisas que contienen información sobre solicitudes de compra directa, tendrá la oportunidad de promocionar su marca en todo el mundo. La plataforma E-exportcity continúa sus operaciones 24 horas al día, 7 días a la semana para brindar servicios en línea en todo el mundo. Nuestro personal experto y experimentado responde a sus problemas lo más rápido posible. La plataforma, donde sectores como el de la automoción, el textil, la cosmética o el inmobiliario se reúnen con un solo clic, espera a sus clientes en muchos países del mundo.",
            temsilcilik: "representación",
            temsilcilikmetin1: "Si deseas ser representante en la plataforma e-exportcity, debes convertirte en miembro del sistema. Puede realizar sus transacciones de membresía utilizando la sección de corredor mientras se convierte en miembro. Puede operar en esta plataforma aprovechando las opciones del paquete de membresía. Puede crear solicitudes de compra y venta de productos y ver las solicitudes en el sistema. Por lo tanto, puede obtener ganancias de forma independiente operando en la plataforma. Otros servicios que ofrece la plataforma e-exportcity son los siguientes. Después de convertirse en miembro del sistema representativo, podrá operar con el código de referencia definido para usted. Puedes ganar dinero invitando a tu círculo, amigos o empresas al sistema. Como sabes, hoy en día todo el mundo quiere iniciar su propio negocio y ser el jefe de su negocio. La plataforma E-exportcity ofrece un mercado donde todos pueden ganar dinero.",
            temsilcilikmetin2: "Para estar en la página de inicio de nuestro sitio como representante, debe invitar al sistema al menos a 250 corredores o empresas. Los corredores y empresas de los que se convierte en miembro tienen la oportunidad de ganar dólares regulares mensualmente mientras sean miembros del sistema. Cuando llegues a 250 miembros, tendrás la oportunidad de convertirte en representante. Al mismo tiempo, puede obtener un ingreso estable. Si el número de delegados que invita sigue siendo inferior a 250 miembros, recibirá un pago único. Ganarás $1 por cada miembro que agregues al sistema. Si registra 100 miembros en el sistema, recibirá un pago único de $100. Cuando el número de negocios que hayas suscrito al sistema sea 250 o más, estarás en la plataforma E-exportcity. Además, obtienes beneficios constantes.",
            ensoneklenenler: "Recientemente añadido",
            urunlerhizmetler: "productos y servicios",
            urunayrintilari: "Detalles de producto",
            sektor: "sector",
            kategori: "categoría",
            miktar: "cantidad",
            seri: "Jerez",
            tarih: "historia",
            acıklama: "explicación",
            satis: "ventas",
            firmabilgisigor: "ver información de la empresa",
            filtreleme: "filtración",
            secimyapiniz: "haz tu elección",
            urunserinumarasi: "Número de serie del producto",
            urun: "producto",
            hizmet: "servicio",
            talep: "pedido",
            ulke: "país",
            filtrele: "filtrar",
            duyurular: "anuncios",
            urunekle: "Agregar producto",
            reklamekle: "agregar anuncio",
            urunadi: "Nombre del producto",
            kaydet: "ahorrar",
            resimekle: "añade una foto",
            urunaciklamasi: "Descripción del Producto",
            baslik: "título",
            altbaslik: "subtitular",
            epostaadresi: "Dirección de correo electrónico",
            sifre: "contraseña",
            girisbasarili: "Inicio de sesión correcto",
            giris: "entrada",
          }
        ],
        "ru": [
          {
            iletisim: "коммуникация",
            dilsec: "выберите язык",
            turkce: "турецкий",
            ingilizce: "Английский",
            arapca: "арабский",
            fransizca: "Французский",
            ispanyolca: "испанский",
            rusca: "Русский",
            girishatali: "запись неверна",
            nasilcalisir: "Как это работает",
            nasilcalisirmetin1: "Проект виртуальной ярмарки разработан в соответствии с новым мировым порядком. На платформе есть предприятия-продавцы и покупатели, а также брокеры. Производители делятся своей продукцией на платформе виртуальной ярмарки, не платя высоких затрат. Компании на платформе виртуальной ярмарки имеют возможность выставляйте свою продукцию по всему миру 24/7 с помощью E-exportcity. Производители на этой платформе быстро предлагают свою продукцию по всему миру. Таким образом, предприятия и брокеры имеют право легко осуществлять свои транзакции онлайн. В то же время Производители получают выгоду от брокерской системы через виртуальную ярмарочную платформу.",
            nasilcalisirmetin2: "Это может свести к минимуму потребности внешней торговли, продаж, маркетинга и расходы на ваши международные поездки. Они предлагают производителям возможность продавать свою продукцию на платформе гораздо короче и по доступным ценам. Став участником системы, компании-покупатели создают заявку на покупку и обращаются к большему количеству компаний-продавцов через компании-производители или брокерские связи. Таким образом, компания доставляет свою продукцию быстро и надежно. Благодаря системе виртуальной ярмарочной платформы сделки купли-продажи осуществляются гораздо выгоднее.",
            brokersistemi: "брокерская система",
            brokersistemimetin1: "Брокерская система – это возможность, предлагаемая людям, которые не хотят работать на оплачиваемой основе в какой-либо фирме или компании. Он предлагает возможность работать фрилансером дома или на отдыхе. Эта платформа, представляющая собой онлайн-бизнес-модель, происходит между производителем и компанией-покупателем. Доходы зарабатываются в виде комиссионных продаж, выступая в качестве посредника для покупателей и продавцов.",
            brokersistemimetin2: "Брокерская система предоставляет услуги по продажам и маркетингу от имени компании между странами, не создавая никаких затрат для компаний-производителей. Таким образом, они позволяют производителям быстро привлечь больше клиентов. Кроме того, предприятиям-покупателям предоставляется быстрый доступ к продукции во многие части мира. Благодаря своей брокерской системе компания исключает расходы на рекламу, маркетинг и персонал всех компаний по всему миру. Если продажи осуществляются в брокерской системе, система комиссионных позволяет брокерам получать высокую прибыль. Таким образом, они становятся частью прибыльной и выигрышной системы.",
            brokersistemimetin3: "Сотрудничая со странами по брокерской системе, на платформе, где соединяются производители и покупатели, создается спрос на продажу. Брокеры-посредники на платформе отвечают на запросы о покупке и ускоряют импорт и экспорт. Таким образом, создается более быстрая торговая среда. Связь между покупателями и продавцами устанавливается на платформе с помощью кода приглашения. Все участники получают определенную комиссию, когда новые брокеры подключаются с помощью кода приглашения. Они продолжают получать регулярные выплаты от компаний, приглашенных брокерами и ставших участниками платформы.",
            reklamvermek: "Рекламировать",
            reklamvermekmetin1: "hazırlanacaktır.",
            guvenlik: "безопасность",
            guvenlikmetin1: "E-exportcity — торговая площадка, объединяющая экспортные и импортные компании со всего мира. Осуществляет надежную торговую деятельность, обеспечивая безопасность компаний. Он был разработан для устранения проблем бизнеса. В то же время он взял на себя задачу предотвращения возможности мошенничества со стороны компаний, работающих на платформе E-exportcity. Предприятия и брокеры, работающие на платформе, создают спрос на продукты и услуги для торговли. Это позволяет вам выполнять все ваши операции, от отраслевого маркетинга до исследования рынка. Для осуществления межконтинентальных импортно-экспортных операций по всему миру вы можете обратиться по адресу электронной почты bilgiticaret@e-exportcity.com. Вы можете получить поддержку от наших команд по этому вопросу. Наши экспертные группы принимают меры предосторожности, чтобы защитить ваш бизнес от мошенничества и финансовых потерь.",
            guvenlikmetin2: "Вы можете сделать первый шаг, став участником платформы, которую предпочитают компании, которые хотят сделать себе имя в глобальном масштабе. После того, как вы станете членом семьи e-exportcity, вам будет отправлено уведомление, чтобы держать вас в курсе всех событий. Изучив из первых рук наиболее точные источники, содержащие информацию о прямых запросах на покупку, вы получаете возможность продвигать свой бренд по всему миру. Платформа E-exportcity продолжает работать круглосуточно и без выходных, предоставляя онлайн-услуги по всему миру. Наши опытные специалисты решают ваши проблемы максимально быстро. Платформа, на которой одним щелчком мыши встречаются такие отрасли, как автомобилестроение, текстиль, косметика и недвижимость, ждет своих клиентов во многих странах мира.",
            temsilcilik: "представление",
            temsilcilikmetin1: "Если вы хотите быть представителем на платформе e-exportcity, вы должны стать участником системы. Вы можете осуществлять членские транзакции, используя раздел брокера, став участником. Вы можете торговать на этой платформе, воспользовавшись опциями членского пакета. Вы можете создавать запросы на покупку и продажу продуктов и просматривать запросы в системе. Таким образом, вы можете самостоятельно получать прибыль, торгуя на платформе. Другие услуги, предлагаемые платформой e-exportcity, заключаются в следующем. После того, как вы станете участником представительской системы, вы сможете торговать с определенным для вас ссылочным кодом. Вы можете зарабатывать деньги, приглашая в систему свой круг, друзей или компании. Как вы знаете, в наши дни каждый хочет начать свой собственный бизнес и быть хозяином своего дела. Платформа E-exportcity предлагает торговую площадку, на которой каждый может заработать деньги.",
            temsilcilikmetin2: "Для того, чтобы находиться на главной странице нашего сайта в качестве представителя, вам необходимо пригласить в систему не менее 250 брокеров или компаний. Брокеры и компании, членами которых вы становитесь, имеют возможность зарабатывать регулярные доллары ежемесячно, пока они являются участниками системы. Когда вы достигнете 250 участников, у вас появится возможность стать представителем. При этом вы можете получать стабильный доход. Если количество приглашенных вами делегатов останется ниже 250 участников, вы получите единовременный платеж. Вы заработаете 1 доллар за каждого участника, которого вы добавите в систему. Если вы зарегистрируете в системе 100 участников, вы получите единовременный платеж в размере 100 долларов США. Когда количество предприятий, подписанных на систему, составит 250 и более, вы окажетесь на платформе E-exportcity. Кроме того, вы получаете стабильную прибыль.",
            ensoneklenenler: "недавно добавленный",
            urunlerhizmetler: "Продукция и Услуги",
            urunayrintilari: "информация о продукте",
            sektor: "сектор",
            kategori: "категория",
            miktar: "количество",
            seri: "шерри",
            tarih: "история",
            acıklama: "объяснение",
            firmabilgisigor: "посмотреть информацию о компании",
            satis: "продажи",
            filtreleme: "фильтрация",
            secimyapiniz: "сделай свой выбор",
            urunserinumarasi: "серийный номер продукта",
            urun: "продукт",
            hizmet: "услуга",
            talep: "запрос",
            ulke: "страна",
            filtrele: "фильтр",
            duyurular: "объявления",
            urunekle: "добавить товар",
            reklamekle: "добавить объявление",
            urunadi: "Название продукта",
            kaydet: "сохранять",
            resimekle: "добавить изображение",
            urunaciklamasi: "Описание продукта",
            baslik: "заголовок",
            altbaslik: "субтитры",
            epostaadresi: "Адрес электронной почты",
            sifre: "пароль",
            girisbasarili: "Авторизация успешна",
            giris: "вход",
          }
        ]
      }
    ],
  },
  getters: {
    _LangStatic(state) {
      return state.LangStatic
    },
    _urunler(state) {
      return state.urunler
    },
    _videolar(state) {
      return state.youtubeVideo
    }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
// youtube video sorgusu için gerekli youtube api sonrasında açılacak olan youtube hesabına yönlendirilecek//

// youtube() {
//     const IframeVideoLists = new Array
//     const apiKey = "AIzaSyAsAJp2MNogVFNOn74JPZuVS89j6Qfp97M"
//     const sorguYoutube = "https://youtube.googleapis.com/youtube/v3/search?part=snippet&channelId=UCL3U6V-sUeYHDINvzGw4BLQ&maxResults=25&order=date&type=video&key="
//     const axiosUrl = `${sorguYoutube}${apiKey}`

//     axios.get(axiosUrl)
//         .then((res) => {
//             $.each(res.data.items, function (i, v) {
//                 if (v.id.videoId !== undefined) {
//                     IframeVideoLists.push([v.snippet.thumbnails.default.url, v.id.videoId, v.snippet.title ,v.snippet.publishedAt])
//                     // console.log(v.snippet.thumbnails.default.url)
//                     // console.log(v.id.videoId)
//                     // console.log(v.snippet.title)
//                    // console.log(v.snippet.publishedAt)

//                 }


//             });
//             //this.IframeList= IframeLists
//             // this.Iframelist = IframeLists

//         })
// }