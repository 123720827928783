import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import $ from "jquery";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import '@/assets/css/style.css'; 
import "@/assets/fonts/Mooli/Mooli-Regular.ttf"
createApp(App)
.use(store)
.use(router)
.use($)
.mount('#app')

