<template>
  <header>
    <nav class="navbar navbar-expand-sm bg-light mx-3">
      <a class="navbar-brand col-4 d-flex align-items-center" href="javascript void(0)">
        <img src="../src/assets/img/headerpic.png" alt="" class="d-inline-block align-text-top w-100">
        <i>E-Exportcity</i>
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTop"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <!-- <i class="fa-solid fa-ellipsis"></i> -->
        <i class="fa-solid fa-language"></i>
      </button>
      <div class="collapse navbar-collapse justify-content-end" id="navbarTop">
        <ul class="nav d-flex flex-column">

          <li class="nav-item m-auto select_menu">
            <select id="langselect" @change="HtmlLang" class="form-select-sm text-capitalize" aria-label="Default select">
              <option class="text-capitalize" lang="tr" value="tr">{{ this._LangStatic[0][this.props][0].turkce }}
              </option>
              <option class="text-capitalize" value="en">{{ this._LangStatic[0][this.props][0].ingilizce }}</option>
              <option class="text-capitalize" value="ar">{{ this._LangStatic[0][this.props][0].arapca }}</option>
              <option class="text-capitalize" value="fr">{{ this._LangStatic[0][this.props][0].fransizca }}</option>
              <option class="text-capitalize" value="es">{{ this._LangStatic[0][this.props][0].ispanyolca }}</option>
              <option class="text-capitalize" value="ru">{{ this._LangStatic[0][this.props][0].rusca }}</option>
            </select>
          </li>
        </ul>
      </div>
    </nav>
    <div class="iletisim">
      <small>

        <ul class="list-group list-group-horizontal d-flex justify-content-between bg-transparent border-0">

          <li class="list-group-item p-0 bg-transparent border-0">
            <ul class="list-group list-group-horizontal bg-transparent border-0">
              <li class="list-group-item border-0 d-flex bg-transparent border-0">
                <p class="text-capitalize"><strong>{{ this._LangStatic[0][this.props][0].iletisim }}</strong></p>
              </li>
              <li class="list-group-item border-0 mx-1 p-0 bg-transparent border-0 color5abfab"><i class="fa-solid fa-phone me-1"></i> +90533 233 22 22</li>
              <li class="list-group-item border-0 mx-1 p-0 bg-transparent border-0 color5abfab"><i
                  class="fa-solid fa-square-envelope me-1"></i>...@e-exportcity.com </li>
            </ul>
          </li>

          <li class="list-group-item p-0 bg-transparent border-0">
            <ul class="list-group list-group-horizontal bg-transparent border-0">
 
              <li class="list-group-item border-0 mx-1 p-0 bg-transparent border-0"><a class="nav-link" href="#"><i
                    class="p-0 mt-1 fa-brands fa-youtube "></i></a></li>
              <li class="list-group-item border-0 mx-1 p-0 bg-transparent border-0"><a class="nav-link" href="#"><i
                    class="p-0 mt-1 fa-brands fa-square-facebook"></i></a></li>
              <li class="list-group-item border-0 mx-1 p-0 bg-transparent border-0"><a class="nav-link" href="#"><i
                    class="p-0 mt-1 fa-brands fa-instagram"></i></a></li>
            </ul>
          </li>

        </ul>
      </small>

    </div>

  </header>
  <router-view />
  <!--LOGİN sonrası active-->

  <nav id="menu2" class="navbar navbar-expand-sm navbar-light fixed-bottom">
    <div class="container-fluid justify-content-center">
      <button class="navbar-toggler mb-2 bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">

        <i class="fa-solid fa-ellipsis"></i>
      </button>
      <div class="collapse navbar-collapse justify-content-center" id="navbarNav">
        <ul class="navbar-nav flex-row justify-content-center">
          <!--data-bs-toggle="collapse"-->
          <li class="nav-item">
            <router-link to="/home/tr">
              <button @click="LangReset" class="btn nav-link text-uppercase " type="button">
                <i class="fa-solid fa-house"></i>
              </button>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/panel/tr">
              <button @click="LangReset" class="btn nav-link text-uppercase " type="button">
                <i class="fa-regular fa-user"></i>
              </button>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/helper/tr">
              <button @click="LangReset" class="btn nav-link text-uppercase " type="button">
                <i class="fa-regular fa-circle-question"></i>
              </button>
            </router-link>
          </li>
          <li class="nav-item">
            <button @click="LangReset" name="logout" class="nav-link btn text-uppercase">
              <i class="fa-solid fa-arrow-right-from-bracket"></i></button>
          </li>
          <li class="nav-item">
            <router-link to="/login/tr">
              <button @click="LangReset" class="btn nav-link text-uppercase " type="button">
                <i class="fa-solid fa-right-to-bracket"></i>
              </button>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>

import { mapGetters } from "vuex"
const $ = require("jquery")


export default {
  data() {
    return {
      props: "tr",
    }
  },
  methods: {
    HtmlLang() {
      const LangVaue = $("#langselect option:selected").val()
      $("html").attr("lang", LangVaue)
      this.props = LangVaue
      const RoutePath = this.$route.path.split("/")[1]
      const NewRoute = `/${RoutePath}/${LangVaue}`
      this.$router.push({ path: `${NewRoute}` })
    },
    LangReset() {
      $("#langselect option:selected")[0].selected = false
      this.props = "tr"
    },
  },
  computed: {
    ...mapGetters(['_LangStatic']),
  },
}
</script>
